<template>
  <div>
    <b-row class="mb-2 border-bottom pb-2">
      <b-col
        cols="12"
        md="auto"
      >
        <item-statistics
          title="Açık İş Emirleri"
          :value="statistic.opened"
          color="light-danger"
          icon="InfoIcon"
        />
      </b-col>
      <b-col
        cols="12"
        md="auto"
      >
        <item-statistics
          title="Kapalı İş Emirleri"
          :value="statistic.closed"
          color="light-info"
          icon="CheckCircleIcon"
        />
      </b-col>
      <b-col
        cols="12"
        md="auto"
      >
        <item-statistics
          title="Müşteri Kartı"
          :value="statistic.customerCount"
          color="light-warning"
          icon="UsersIcon"
        />
      </b-col>
      <b-col
        cols="12"
        md="auto"
      >
        <item-statistics
          title="KVKK Onaylı"
          :value="statistic.kvkkApproved"
          color="light-success"
          icon="CheckCircleIcon"
        />
      </b-col>
      <b-col
        cols="12"
        md="auto"
      >
        <item-statistics
          title="KVKK Onaysız"
          :value="statistic.kvkkUnapproved"
          color="light-danger"
          icon="XCircleIcon"
        />
      </b-col>
    </b-row>
    <b-row class="match-height d-flex align-items-center">
      <b-col>
        <b-input-group>
          <b-form-input
            v-model="filterDetail.keywords"
            placeholder="Kayıt ara.."
            @keydown.enter="filterAction"
          />
          <b-input-group-append>
            <b-button
              variant="primary"
              @click="filterAction"
            >
              <FeatherIcon icon="SearchIcon" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col cols="auto">
        <b-form-checkbox
          v-model="detailFilter"
          switch
          inline
          @click="detailFilter = !detailFilter"
        >
          Detaylı Arama
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row v-if="detailFilter">
      <b-col
        cols="12"
        md="4"
        class="mt-1"
      >
        <select-brand />
      </b-col>
      <b-col
        cols="12"
        md="4"
        class="mt-1"
      >
        <select-location />
      </b-col>
      <b-col
        cols="12"
        md="4"
        class="mt-1"
      >
        <select-user />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <SearchBarTracingDatesCard />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <SearchBarDBDatesCard />
      </b-col>
      <b-col
        cols="12"
        class="text-center"
      >
        <b-button
          variant="primary"
          :disabled="dataLoading"
          @click="filterAction"
        >
          <FeatherIcon icon="FilterIcon" />
          Filtrele
        </b-button>
        <b-button
          variant="warning"
          class="ml-1"
          @click="resetFilter"
        >
          <FeatherIcon icon="TrashIcon" />
          Filtreyi Sıfırla
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BButton,
} from 'bootstrap-vue'
import ItemStatistics from '@/views/Reports/Service/RPA/Elements/ItemStatistics.vue'
import SelectBrand from '@/views/Reports/Service/RPA/Elements/SelectBrand.vue'
import SelectLocation from '@/views/Reports/Service/RPA/Elements/SelectLocation.vue'
import SelectUser from '@/views/Reports/Service/RPA/Elements/SelectUser.vue'
import SearchBarTracingDatesCard from '@/views/Reports/Service/RPA/Elements/SearchBarTracingDatesCard.vue'
import SearchBarDBDatesCard from '@/views/Reports/Service/RPA/Elements/SearchBarDBDatesCard.vue'

export default {
  name: 'ServiceRPASearchBar',
  components: {
    ItemStatistics,
    SearchBarTracingDatesCard,
    SearchBarDBDatesCard,
    SelectBrand,
    SelectLocation,
    SelectUser,
    BRow,
    BCol,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BButton,
  },
  props: {
    filterAction: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      detailFilter: true,
    }
  },
  computed: {
    statistic() {
      return this.$store.getters['serviceRPA/statistic']
    },
    filterDetail() {
      return this.$store.getters['serviceRPA/dataFilter']
    },
    dataLoading() {
      return this.$store.getters['serviceRPA/dataLoading']
    },
  },
  methods: {
    resetFilter() {
      this.$store.dispatch('serviceRPA/resetFilterData')
    },
  },
}
</script>
