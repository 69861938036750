<template>
  <b-card no-body>
    <b-card-body>
      <service-r-p-a-search-bar :filter-action="filterData" />
    </b-card-body>
    <template v-if="dataLoading">
      <loading />
    </template>
    <template v-else>
      <b-table
        responsive
        :items="dataList"
        striped
        small
        :fields="fields"
        class="font-small-3 text-nowrap"
      >
        <template #cell(Is_Emri_No)="data">
          <div class="font-weight-bold text-primary font-small-2">
            {{ data.item.Is_Emri_No }}
          </div>
        </template>
        <template #cell(Musteri_Adi)="data">
          <div class="font-weight-bold text-info font-small-2">
            {{ data.item.TC_Kimlik }}
          </div>
          <div class="font-weight-bold text-primary">
            {{ data.item.Musteri_Adi }}
          </div>
          <div class="font-weight-bold text-muted">
            {{ data.item.Telefon }}
          </div>
          <div class="font-weight-bold text-muted">
            {{ data.item.E_Posta }}
          </div>
          <div>
            <template v-if="data.item.id_com_customer">
              <div class="d-flex align-items-center">
                <b-badge
                  :variant="data.item.kvkk === '1'? 'light-success' : 'light-warning'"
                  size="lg"
                >
                  <template
                    v-if="data.item.kvkk === '1'"
                  >
                    KVKK ONAYLI
                  </template>
                  <template v-else>
                    KVKK ONAYSIZ
                  </template>
                </b-badge>
                <b-badge
                  v-if="data.item.kvkk === '1'"
                  :variant="getKvkkDiff(data.item) > 7? 'light-danger' : 'light-success'"
                  class="ml-1"
                >
                  <FeatherIcon icon="InfoCircleIcon" />
                  {{ getKvkkDiff(data.item) | toNumber }} Gün Önce
                </b-badge>
              </div>
            </template>
            <template v-else>
              <b-badge variant="light-danger">
                Müşteri kartı bulunamadı
              </b-badge>
            </template>
          </div>
        </template>
        <template #cell(Marka)="data">
          <div class="font-weight-bold text-primary">
            {{ data.item.Marka }}
          </div>
          <div class="font-small-2 text-muted">
            {{ data.item.Arac_Model }} {{ data.item.Model_Yili }} <span v-if="data.item.Km">({{ data.item.Km | toNumber }} km)</span>
          </div>
          <div class="font-weight-bold text-info">
            {{ data.item.Plaka }}
          </div>
          <div class="font-weight-bold text-muted font-small-2">
            {{ data.item.Sase_No }} {{ data.item.Motor_No }}
          </div>
        </template>
        <template #cell(Randevu_Tarihi)="data">
          <div class="font-weight-bold text-primary font-small-2">
            {{ moment(data.item.Randevu_Tarihi).format('DD.MM.YYYY HH:mm') }}
          </div>
        </template>
        <template #cell(DB_Yazdirma_Tarihi)="data">
          <div class="font-weight-bold text-danger font-small-2">
            {{ moment(data.item.DB_Yazdirma_Tarihi).format('DD.MM.YYYY HH:mm') }}
          </div>
        </template>
      </b-table>
      <b-card-footer>
        <b-row>
          <b-col>
            <b-pagination
              v-model="currentPage"
              :total-rows="dataCounts"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
          <b-col>
            <div class="text-right">
              <p>Toplam <b>{{ dataCounts }}</b> adet sonuç bulundu.</p>
            </div>
          </b-col>
        </b-row>
      </b-card-footer>
    </template>
  </b-card>
</template>
<script>
import {
  BCard, BCardBody, BTable, BPagination, BCardFooter, BRow, BCol, BBadge,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ServiceRPASearchBar from '@/views/Reports/Service/RPA/SearchBar.vue'
import Loading from '@/layouts/components/Loading.vue'

export default {
  name: 'Index',
  components: {
    Loading,
    ServiceRPASearchBar,
    BCard,
    BCardBody,
    BTable,
    BPagination,
    BCardFooter,
    BRow,
    BCol,
    BBadge,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 50,
      fields: [
        {
          key: 'Is_Emri_No',
          label: 'İŞ EMRİ NO',
          thClass: 'text-left width-50 text-nowrap',
          tdClass: 'text-left width-50 text-nowrap',
        },
        {
          key: 'Musteri_Adi',
          label: 'MÜŞTERİ',
        },
        {
          key: 'Marka',
          label: 'MARKA / ARAÇ',
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'Randevu_Tarihi',
          label: 'RANDEVU',
          thClass: 'text-left width-50 text-nowrap',
          tdClass: 'text-left width-50 text-nowrap',
        },
        {
          key: 'Lokasyon',
          label: 'LOKASYON',
          thClass: 'text-left width-50 text-nowrap',
          tdClass: 'text-left width-50 text-nowrap',
        },
        {
          key: 'Danisman',
          label: 'DANIŞMAN',
          thClass: 'text-left width-50 text-nowrap',
          tdClass: 'text-left width-50 text-nowrap',
        },
        {
          key: 'DB_Yazdirma_Tarihi',
          label: 'AKTARIM TARİHİ',
          thClass: 'text-left width-50 text-nowrap',
          tdClass: 'text-left width-50 text-nowrap',
        },
      ],
      dataQuery: {
        select: [
          'com_customer.id AS id_com_customer',
          'com_customer.kvkk AS kvkk',
          'com_customer.kvkkdate AS kvkkdate',
          'com_customer.created AS created',
          'com_customer.modified AS modified',
          'tbl_IsEmirleri.Belge_Tipi AS Belge_Tipi',
          'tbl_IsEmirleri.Musteri_Adi AS Musteri_Adi',
          'tbl_IsEmirleri.TC_Kimlik AS TC_Kimlik',
          'tbl_IsEmirleri.Telefon AS Telefon',
          'tbl_IsEmirleri.E_Posta AS E_Posta',
          'tbl_IsEmirleri.Plaka AS Plaka',
          'tbl_IsEmirleri.Arac_Model AS Arac_Model',
          'tbl_IsEmirleri.Marka AS Marka',
          'tbl_IsEmirleri.Model_Yili AS Model_Yili',
          'tbl_IsEmirleri.Randevu_Tarihi AS Randevu_Tarihi',
          'tbl_IsEmirleri.Lokasyon AS Lokasyon',
          'tbl_IsEmirleri.Danisman AS Danisman',
          'tbl_IsEmirleri.Musteri_Talebi AS Musteri_Talebi',
          'tbl_IsEmirleri.Gorusme_Notu AS Gorusme_Notu',
          'tbl_IsEmirleri.Il_Ilce AS Il_Ilce',
          'tbl_IsEmirleri.Is_Emri_No AS Is_Emri_No',
          'tbl_IsEmirleri.Sase_No AS Sase_No',
          'tbl_IsEmirleri.Motor_No AS Motor_No',
          'tbl_IsEmirleri.Km AS Km',
          'tbl_IsEmirleri.DB_Yazdirma_Tarihi AS DB_Yazdirma_Tarihi',
        ],
        limit: 50,
        start: 0,
        joinCustomer: true,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['serviceRPA/dataList']
    },
    dataCounts() {
      return this.$store.getters['serviceRPA/dataCounts']
    },
    filterParams() {
      return this.$store.getters['serviceRPA/dataFilter']
    },
    saveData() {
      return this.$store.getters['serviceRPA/dataSaveStatus']
    },
    dataLoading() {
      return this.$store.getters['serviceRPA/dataLoading']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * 25
      this.getDataList()
    },
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.getDataList()
    },
  },
  created() {
    this.getDataList()
    this.getFilterParams()
  },
  methods: {
    getKvkkDiff(data) {
      return this.moment(data.DB_Yazdirma_Tarihi, 'YYYY-MM-DD').diff(this.moment(data.kvkkdate, 'YYYY-MM-DD'), 'days')
    },
    getFilterParams() {
      this.$store.dispatch('serviceRPA/getFilterParams')
    },
    filterData() {
      const where = {}
      if (this.filterParams.keywords) {
        this.dataQuery.or_like = {
          'tbl_IsEmirleri.Is_Emri_No': this.filterParams.keywords,
          'tbl_IsEmirleri.Musteri_Adi': this.filterParams.keywords,
          'tbl_IsEmirleri.TC_Kimlik': this.filterParams.keywords,
          'tbl_IsEmirleri.Telefon': this.filterParams.keywords,
          'tbl_IsEmirleri.E_Posta': this.filterParams.keywords,
          'tbl_IsEmirleri.Arac_Model': this.filterParams.keywords,
          'tbl_IsEmirleri.Marka': this.filterParams.keywords,
          'tbl_IsEmirleri.Plaka': this.filterParams.keywords,
          'tbl_IsEmirleri.Danisman': this.filterParams.keywords,
        }
      } else {
        delete this.dataQuery.or_like
      }
      if (this.filterParams.brand !== null) {
        where['tbl_IsEmirleri.Marka'] = this.filterParams.brand
      }
      if (this.filterParams.location !== null) {
        where['tbl_IsEmirleri.Lokasyon'] = this.filterParams.location
      }
      if (this.filterParams.user !== null) {
        where['tbl_IsEmirleri.Danisman'] = this.filterParams.user
      }
      if (this.filterParams.randevu_starihi) {
        where['date(tbl_IsEmirleri.Randevu_Tarihi) >='] = this.filterParams.randevu_starihi
      }
      if (this.filterParams.randevu_etarihi) {
        where['date(tbl_IsEmirleri.Randevu_Tarihi) <='] = this.filterParams.randevu_etarihi
      }
      if (this.filterParams.DB_Yazdirma_sTarihi) {
        where['date(tbl_IsEmirleri.DB_Yazdirma_Tarihi) >='] = this.filterParams.DB_Yazdirma_sTarihi
      }
      if (this.filterParams.DB_Yazdirma_eTarihi) {
        where['date(tbl_IsEmirleri.DB_Yazdirma_Tarihi) <='] = this.filterParams.DB_Yazdirma_eTarihi
      }
      this.dataQuery.where = where
      this.getDataList()
    },
    getDataList() {
      this.$store.dispatch('serviceRPA/getDataList', this.dataQuery)
    },
    removeData(id) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('serviceRPA/removeData', id)
          this.$swal({
            icon: 'success',
            title: this.$store.state.app.removeResultTitle,
            text: this.$store.state.app.removeResultMessage,
            confirmButtonText: this.$store.state.app.removeResultClose,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
